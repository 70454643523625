// eslint-disable-next-line import/prefer-default-export
export const quoteList = [
  {
    district: 'Richardson ISD',
    message: 'Presto Assistant has been a game changer for our district fine arts programs. Our teachers are using all aspects of the program to save time and energy that they can invest in educating kids. Teachers consistently rave about the high level of customer support and the ability to connect directly with the developers. Presto listens, responds, and helps us achieve excellence in the arts by supporting arts educators\' needs.',
    name: 'Jeff Bradford',
    school: null,
    title: 'Executive Director of Fine Arts',
  },
  {
    district: 'Keller ISD',
    message: 'The Presto Assistant Team has gone out of their way to listen to the needs of our directors, students, and families, to create a robust fine arts management platform that has made organizing our programs so much easier! The Presto Team values our partnership and continues to update the program based on the needs of its users.',
    name: 'David Wright',
    school: null,
    title: 'Assistant Director of Fine Arts',
  },
  {
    district: 'McKinney ISD',
    message: 'Customer service and customer experience with Presto Assistant are second to none. Their team is friendly and very responsive to teacher feedback. Presto was created for fine arts educators by fine arts educators who thoroughly understand what it takes to run a successful program. The developers crafted a product that is user-friendly, efficient, and relevant. Presto reduces teachers’ time on administrative tasks to allow them to focus their energy on teaching students. When we introduced Presto to our teachers, they repeatedly applauded and cheered at its features because they knew they would receive the best product on the market.',
    name: 'Jared Critchfield',
    school: null,
    title: 'Director of Fine Arts',
  },
  {
    district: 'Richardson ISD',
    message: 'Our district\'s transition to Presto Assistant couldn\'t have been smoother. They made it so easy to transport all the data we needed. The learning curve was short and we were down to business with organizing and running our programs in no time. I really love the "meat and potatoes" approach to data management along with the at-a-glance features with relevant data. And to top it off, Austin and Mike have made it their mission to respond to provide quick and responsive customer service.',
    name: 'Toby Jones',
    school: 'Berkner HS',
    title: 'Assistant Orchestra Director',
  },
  {
    district: 'Red Oak ISD',
    message: 'Presto is a breath of fresh air after having used a competitor\'s product for a number of years. The user interface is so well thought out and easy to use. Setting up student and parent accounts is a breeze. We\'ve never used the financial aspect of the competitor\'s product, but fully intend to use the financial features of Presto. We also really appreciate the ability to reach out and receive timely feedback from the creators any time we have a question. We cannot recommend Presto enough to help manage your program!',
    name: 'Chris Eaton',
    school: 'Red Oak MS',
    title: 'Assistant Band Director',
  },
  {
    district: 'Munday Consolidated ISD',
    message: 'I found Presto Assistant to be very easy to learn. The customer service and development team are very responsive and helpful. Much easier to learn than [other tools].',
    name: 'Lee Brossette',
    school: 'Munday HS',
    title: 'Band Director',
  },
  {
    district: 'Keller ISD',
    message: 'We have spent a whole year with Presto and are thrilled with its feature-rich but simple-to-use design — it does what it needs to and saves time! The best part, though, is Team Presto\'s approach to customer service. I can\'t say enough about how fast these guys solve problems — sometimes before I even know I have them — and how hard they continue to work to make this system exactly what we need it to be for our program.',
    name: 'Daniel Bloss',
    school: 'Keller HS',
    title: 'Director of Bands',
  },
  {
    message: 'Presto Assistant is a fantastic tool that makes the administrative tasks of the job much easier. Austin and Mike are fantastic to work with and are always willing to make updates so that the product works even better!',
    name: 'Chris Evans',
    school: 'University of Texas at Arlington',
    title: 'Associate Director of Bands',
  },
  {
    district: 'Port Neches-Groves ISD',
    message: '...Presto Assistant has been awesome! The customer service is top notch and they want to help! Presto listens to their customers on what can help make our lives easier in the Fine Arts realm. I recommend this program to everyone, if you are looking to switch.',
    name: 'Austin Wolfe',
    school: 'Groves MS',
    title: 'Head Band Director',
  },
  {
    district: 'Lewisville ISD',
    message: 'Presto Assistant provides an all-in-one solution that makes managing our programs seamless and efficient. The intuitive design and ease of use have drastically reduced the administrative burden on us, the teachers, and allows us to focus on what truly matters…teaching and inspiring students!  The support team is exceptional, always available and eager to assist with any need, and they genuinely value our feedback.  Presto continues to evolve in response to the real-world challenges we face, making it an indispensable tool for fine arts educators who want to elevate their programs.',
    name: 'Ross Patterson',
    school: 'Shadow Ridge MS',
    title: 'Director of Bands',
  },
];

export const shortQuoteList = quoteList.slice(0, 4);
